import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";
import { MenuOutlined } from "@ant-design/icons";

import { Menu, Dropdown, Button, Modal } from "antd";

import useTranslation from "../../hooks/useTranslation";

import { actions } from "../../store";

import REQUESTS from "../../api/requests";

import NavDrawer from "./NavDrawer";

import Languages from "./Languages";

import TEXT from "../../config/text";
import PATHS from "../../config/paths";
import IMAGES from "../../config/images";
import { RESELLER_HOST } from "../../config";

import GlobalEditor from "../../editors/GlobalEditor";

import style from "../style/navbar.module.scss";

export default function Navbar() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const { setAppInfoInRedux } = bindActionCreators(actions, dispatch);

  const { webSettings } = useSelector((store) => store.globalState);

  const translation = useTranslation();

  const { isAdmin } = useSelector((store) => store.globalState);

  const { widgets } = useSelector((state) => state.globalState);

  const [visible, setVisible] = useState(false);

  const [underConstruction, setUnderConstruction] = useState(false);

  const [modal, contextHolder] = Modal.useModal();

  const selectedLanguage = useSelector((store) => store.globalState.selectedLanguage);

  const checkFromWidget = (widget) => {
    if (widgets && widgets[widget] && widgets[widget].enabled === "true") {
      return true;
    }
    return false;
  }

  const handleText = (page, text) => {
    if (translation?.pages?.[page]?.[text]) {
      return translation?.pages?.[page]?.[text];
    }

    return TEXT?.pages?.[page]?.[text];
  };

  const logout = () => {
    const TOKEN = localStorage.getItem("TOKEN");

    modal.confirm({
      title: null,
      icon: null,
      content: "Do you want to logout ?",
      okText: 'LOG OUT',
      cancelText: 'CANCEL',
      onOk: () => {
        if (TOKEN) {
          localStorage.removeItem("TOKEN");
          navigate(PATHS.LOGIN);
        }
      },
      className: 'ant-logout-confirm',
    });
  };

  const logoutFromMultipleDevicePage = () => {
    const JWT = localStorage.getItem("JWT");

    modal.confirm({
      title: null,
      icon: null,
      content: "Do you want to logout ?",
      okText: 'LOG OUT',
      cancelText: 'CANCEL',
      onOk: () => {
        if (JWT) {
          localStorage.removeItem('JWT');
          navigate(PATHS.MULTIPLE_DEVICE_LOGIN);
        }
      },
      className: 'ant-logout-confirm',
    });
  };

  const getAppInfo = () => {
    function callback(data) {
      setAppInfoInRedux(data);

      if (data.under_construction) {
        navigate("/under_construction");
        setUnderConstruction(data.under_construction);
      }
    }

    function errorCallback(err) {
      navigate("/under_construction");
      window.location.href = "/#/under_construction";
    }

    REQUESTS.APP_INFO(callback, errorCallback);
  };

  useEffect(() => {

    if (underConstruction && location.pathname !== "/under_construction") {
      navigate("/under_construction");
    }

  }, [location, underConstruction]);

  const menu = (
    <Menu className={style["reseller-dropdown"]}>
      <Menu.Item>
        <NavLink
          to={PATHS.RESELLER}
          rel="noreferrer"
          className={({ isActive }) =>
            isActive
              ? `${style["dropdown-item"]} ${style["active-link"]}`
              : style["dropdown-item"]
          }
        >
          {handleText("register", "page_name")}
        </NavLink>
      </Menu.Item>

      <Menu.Item>
        <a
          href={RESELLER_HOST + "#/login"}
          target="_blank"
          rel="noreferrer"
          className={style["dropdown-item"]}
        >
          {translation?.general?.login || TEXT?.general?.login}
        </a>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    getAppInfo();
  }, []);

  return (
    <>
      <div className={style["menu-icon"]} onClick={() => setVisible(true)}>
        <MenuOutlined color="white" />
      </div>
      <div className={style.container}>
        <NavLink to={PATHS.HOME}>
          <img
            src={webSettings?.globals.logo || IMAGES.LOGO}
            alt="brand-logo"
            loading="lazy"
            className={style.logo}
            style={{ opacity: webSettings?.globals.logo ? 1 : 0 }}
          />
        </NavLink>
        <div className={style.routes}>
          <NavLink
            to={PATHS.HOME}
            className={({ isActive }) =>
              isActive
                ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                : style["menu-list__item__link"]
            }
          >
            {handleText("home", "page_name")}
          </NavLink>
          {checkFromWidget("payment") && (
            <NavLink
              to={PATHS.ACTIVATION}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("activation", "page_name")}
            </NavLink>
          )}

          {!checkFromWidget("hide_upload_playlist") && (
            <NavLink
              to={PATHS.UPLOAD_PLAYLIST}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("upload_playlist", "page_name")}
            </NavLink>
          )}

          {checkFromWidget("referral") && checkFromWidget("reseller") && (
            <Dropdown overlay={menu}
              placement="bottomCenter"
            >
              <div
                className={style["menu-list__item__link"]}
              >
                {handleText("reseller", "page_name")}
              </div>
            </Dropdown>
          )}

          {checkFromWidget("news") && (
            <NavLink
              to={PATHS.NEWS}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("news", "page_name")}
            </NavLink>
          )}

          {window.DOC_URL && (
            <a
              href={window.DOC_URL}
              className={style["menu-list__item__link"]}
            >
              Docs
            </a>
          )}

          {checkFromWidget("pricing") && (
            <NavLink to={PATHS.PRICING} className={({ isActive }) =>
              isActive
                ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                : style["menu-list__item__link"]
            }>
              {selectedLanguage?.iso_code == "hy" ?
                "Գնացուցակ" :
                handleText("pricing", "page_name")}
            </NavLink>
          )}

          {checkFromWidget("support") && (
            <NavLink
              to={PATHS.CONTACT_US}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("contact_us", "page_name")}
            </NavLink>
          )}

          {checkFromWidget("installation_guide") &&
            <NavLink
              to={PATHS.INTALLATION_GUIDE}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("installation_guide", "page_name")}
            </NavLink>
          }

          {isAdmin && (
            <NavLink
              to={PATHS.CHAT}
              className={({ isActive }) =>
                isActive
                  ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                  : style["menu-list__item__link"]
              }
            >
              {handleText("chat", "page_name")}
            </NavLink>
          )}
        </div>
      </div>

      <div className={style["navbar-login-lang-section"]}>
        {(checkFromWidget("user_login") || checkFromWidget("user_login2")) &&
          <Button className={style["btn"]} onClick={() => navigate(PATHS.LOGIN)}>
            {checkFromWidget("user_login") ?
              handleText("login", "page_name") :
              checkFromWidget("user_login2") &&
              handleText("login_2", "page_name")
            }
          </Button>
        }

        {(window.location.hash === "#/playlists" ||
          window.location.hash === "#/manage-playlists/profile") &&
          <Button className={style["btn"]} onClick={logout}>
            {handleText("playlist", "logout_button_text")}
          </Button>
        }

        {checkFromWidget("multiple_device_register") &&
          <div className={style["log-button"]}>
            {
              window.location.hash === "#/devices" ?
                <Button className={style["btn"]} onClick={logoutFromMultipleDevicePage}>
                  {handleText("multiple_device_login", "logout")}
                </Button>
                :
                <Button className={style["btn"]} onClick={() => {
                  const JWT = localStorage.getItem("JWT");
                  if (JWT) {
                    navigate(PATHS.DEVICES)
                  } else {
                    navigate(PATHS.MULTIPLE_DEVICE_LOGIN)
                  }
                }}>
                  {handleText("multiple_device_login", "title")}
                </Button>
            }
          </div>
        }
        <Languages />
      </div>

      {isAdmin && <GlobalEditor />}

      <NavDrawer visible={visible} onClose={() => setVisible(false)} />

      {contextHolder}
    </>
  );
}
