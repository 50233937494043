import { useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";

import { Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";
import PATHS from "../../config/paths";

import style from "../style/navbar.module.scss";
import { RESELLER_HOST } from "../../config";

const NavDrawer = ({ visible, onClose }) => {
  const translation = useTranslation();

  const { widgets } = useSelector((state) => state.globalState);
  const isAdmin = useSelector((store) => store.globalState.isAdmin);

  const handleText = (page, text) => {
    if (translation?.pages?.[page]?.[text]) {
      return translation?.pages?.[page]?.[text];
    }

    return TEXT?.pages?.[page]?.[text];
  };

  const checkFromWidget = (widget) => {
    if (widgets && widgets[widget] && widgets[widget].enabled === "true") {
      return true;
    }
    return false;
  }

  useLayoutEffect(() => {
    document.body.style.overflow = visible ? "hidden" : "unset";
  }, [visible]);

  return (
    <Drawer
      placement="left"
      onClose={onClose}
      open={visible}
      bodyStyle={{ padding: 0 }}
      headerStyle={{ display: "none" }}
    >
      <div className={style["nav-drawer"]}>
        <CloseOutlined
          style={{
            position: "absolute",
            right: "29px",
            top: "23px",
            color: "#d3caca",
            fontSize: "19px",
            cursor: " pointer",
          }}
          onClick={onClose}
        />
        <NavLink onClick={onClose} to={PATHS.HOME}>
          {handleText("home", "page_name")}
        </NavLink>
        {checkFromWidget("payment") && (
          <NavLink onClick={onClose} to={PATHS.ACTIVATION}>
            {handleText("activation", "page_name")}
          </NavLink>
        )}

        {!checkFromWidget("hide_upload_playlist") && (
          <NavLink onClick={onClose} to={PATHS.UPLOAD_PLAYLIST}>
            {handleText("upload_playlist", "page_name")}
          </NavLink>
        )}


        {checkFromWidget("news") && (
          <NavLink to={PATHS.NEWS} onClick={onClose}>
            {handleText("news", "page_name")}
          </NavLink>
        )}
        {window.DOC_URL && <a href={window.DOC_URL}>Docs</a>}
        {checkFromWidget("pricing") && (
          <NavLink to={PATHS.PRICING} onClick={onClose}>{handleText("pricing", "page_name")}</NavLink>
        )}

        {checkFromWidget("support") && (
          <NavLink to={PATHS.CONTACT_US} onClick={onClose}>
            {handleText("contact_us", "page_name")}
          </NavLink>
        )}
        {checkFromWidget("installation_guide") &&
          <NavLink to={PATHS.INTALLATION_GUIDE} onClick={onClose}>
            {handleText("installation_guide", "page_name")}
          </NavLink>
        }

        {checkFromWidget("referral") &&
          checkFromWidget("reseller") && (
            <NavLink onClick={onClose} to={PATHS.RESELLER}>
              {handleText("register", "register")}
            </NavLink>
          )}

        {checkFromWidget("referral") &&
          checkFromWidget("reseller") &&
          <a
            onClick={onClose}
            target="_blank"
            rel="noreferrer"
            href={RESELLER_HOST}
          >
            {handleText("register", "reseller_login")}
          </a>

        }

        {isAdmin && (
          <NavLink
            to={PATHS.CHAT}
            className={({ isActive }) =>
              isActive
                ? `${style["menu-list__item__link"]} ${style["active-link"]}`
                : style["menu-list__item__link"]
            }
          >
            {handleText("chat", "page_name")}
          </NavLink>
        )}
      </div>
    </Drawer>
  );
};

export default NavDrawer;
